import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Grid,
  SelectChangeEvent,
  styled,
  TableCell,
  TableRow,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavBar from "../Navigation/Navigation";

// Define the interface for the product style data
interface ProductStyle {
  Supplier_Code: string;
  Total_Quantity: number;
  Supplier_Total_Cost: number;
  Total_Retail: number;
  Margin_Percentage: number;
  Cost_Percentage: number;
}

// Define custom styled components
const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Make text bold
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5", // Light grey for odd rows
  },
  "&:hover": {
    backgroundColor: "#e0e0e0", // Slightly darker on hover
  },
});

const InventoryReport: React.FC = () => {
  const [location, setLocation] = useState<string>("");
  const [data, setData] = useState<ProductStyle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState({ totalCost: 0, totalMargin: 0 });

  // Detect screen size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Update the event type to SelectChangeEvent
  const handleLocationChange = async (event: SelectChangeEvent<string>) => {
    const selectedLocation = event.target.value as string;
    setLocation(selectedLocation);
    await fetchData(selectedLocation); // Automatically fetch data when location changes
  };

  const fetchData = async (location: string) => {
    setLoading(true);
    try {
      const response = await fetch(`/salesByBrand/InvReport?location=${location}`);
      const result = await response.json();
      setData(result);
      calculateTotals(result); // Calculate totals after data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Function to calculate totals (total cost and margin percentage)
  const calculateTotals = (data: ProductStyle[]) => {
    const totalCost = data.reduce((acc, item) => acc + item.Supplier_Total_Cost, 0);
    const totalRetail = data.reduce((acc, item) => acc + item.Total_Retail, 0);

    // Calculate total margin percentage using the given formula
    const totalMargin =
      totalRetail && totalCost ? ((totalRetail - totalCost) / totalRetail) * 100 : 0;

    setTotals({ totalCost, totalMargin });
  };

  const formatDollar = (value: number | undefined): string => {
    if (value === undefined) {
      return "$0.00"; // or however you wish to format undefined values
    }
    return value.toLocaleString("en-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: 2,
    });
  };

  const formatNumber = (value: number | undefined): string => {
    return value !== undefined ? value.toLocaleString() : "0";
  };

  const locations = [
    "Alliston",
    "Brampton",
    "Cartwright",
    "DC",
    "DC-Caledonia",
    "DC-Queensway",
    "Dufferin-Orfus",
    "New-Scarborough",
    "Newmarket",
    "Niagara",
    "OPM",
    "OPM-Hamilton",
    "OPM-Online",
    "Pickering",
    "Queensway",
    "Scarborough",
    "Steeles",
    "Vaughan",
    "Web",
    "Yorkgate",
  ];

  return (
    <>
      <NavBar />
      <Container>
        <Typography variant="h4" gutterBottom style={{ textAlign: "center", margin: "5px" }}>
          Inventory Report
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Select
              value={location}
              onChange={handleLocationChange}
              displayEmpty
              fullWidth
              variant="outlined"
              style={{ marginBottom: 20 }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set max height for scrollable dropdown
                    width: 250, // Set dropdown width
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                Select Location
              </MenuItem>
              {locations.map((loc, index) => (
                <MenuItem
                  key={index}
                  value={loc}
                  sx={{ height: 35, fontSize: "14px" }} // Reduce height and font size of each MenuItem
                >
                  {loc}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        {/* Show loading message */}
        {loading && <Typography>Loading...</Typography>}

        {/* Display totals */}
        {!loading && data.length > 0 && (
          <Grid>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  margin: "5px",
                  padding: "8px",
                  background: "#3b444b",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "18pt",
                }}
              >
                Inventory Value: {formatDollar(totals.totalCost)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                align="center"
                style={{
                  border: "double",
                  margin: "5px",
                  padding: "8px",
                  background: "#3b444b",
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "18pt",
                }}
              >
                Gross Margin : {totals.totalMargin.toFixed(2)}%
              </Typography>
            </Box>
          </Grid>
        )}

        {/* Table with sticky header and scrollable body */}
        {data.length > 0 && !loading && (
          <TableContainer
            component={Paper}
            style={{
              maxHeight: isMobile ? 580 : "auto",
              maxWidth: isMobile ? 550 : "100%",
              overflowY: "auto",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Supplier</StyledTableCell>
                  <StyledTableCell align="center">QTY</StyledTableCell>
                  <StyledTableCell align="center">Total Cost</StyledTableCell>
                  <StyledTableCell align="center">Total Retail</StyledTableCell>
                  <StyledTableCell align="center">GM</StyledTableCell>
                  <StyledTableCell align="center">% of Total Cost</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">{row.Supplier_Code}</StyledTableCell>
                    <StyledTableCell align="center">{formatNumber(row.Total_Quantity)}</StyledTableCell>
                    <StyledTableCell align="center">{formatDollar(row.Supplier_Total_Cost)}</StyledTableCell>
                    <StyledTableCell align="center">{formatDollar(row.Total_Retail)}</StyledTableCell>
                    <StyledTableCell align="center">{row.Margin_Percentage.toFixed(2)}%</StyledTableCell>
                    <StyledTableCell align="center">{row.Cost_Percentage.toFixed(2)}%</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </>
  );
};

export default InventoryReport;
